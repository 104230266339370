import React, { useState, useEffect, lazy, Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import { LanguageProvider } from "./context/LanguageContext";
import i18n from "./i18n/i18n";

const Navbar = lazy(() => import("./components/navbar/navbar"));

export default function Root(props) {
  const [visibility, setVisibility] = useState(true);

  useEffect(() => {
    const app = window?.location?.pathname?.split("/")?.[1];
    const excluded_apps = [
      "products",
      "cancellation-service",
      "cancel-subscription",
    ];
    const state = excluded_apps.includes(app) || !app;
    setVisibility(!state);
  }, []);

  const loadCSS = async () => {
    if (visibility) {
      try {
        await import("./assets/css/tailwind.css");
      } catch (err) {
        console.error("Error loading CSS:", err);
      }
    }
  };

  useEffect(() => {
    loadCSS();
  }, [visibility]);

  return (
    <LanguageProvider>
      <I18nextProvider i18n={i18n}>
        {visibility && (
          <Suspense fallback={<div></div>}>
            <Navbar />
          </Suspense>
        )}
      </I18nextProvider>
    </LanguageProvider>
  );
}