import { Alert, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormData } from "../../context/formContext";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "../../context/locationContext";
import { useWhatsapp } from "../../context/whatsappContext";

const PixProcess = ({
  setHeaderVisibility,
  setActionButtonAvailable,
  process = false,
  reserve_id,
  backFromPix,
}) => {
  const { parsedData } = useFormData();
  const [reserveData, setReserveData] = useState(parsedData);
  const [timeoutProcess, setTimeoutProcess] = useState(false);
  const [rejectedPayment, setRejectedPayment] = useState(false);
  const { basePath } = useLocation();
  const { t } = useTranslation();
  const { whatsapp_number } = useWhatsapp();

  useEffect(() => {
    setHeaderVisibility(!process);
    setActionButtonAvailable(!process);
  }, [process]);

  useEffect(() => {
    if (process && reserve_id) {
      let counter = 0;

      const intervalId = setInterval(async () => {
        try {
          const res = await fetch(
            "https://app.assist-365.com/api/ipn-pix-validate?reserve_id=" +
              reserve_id
          );
          const data = await res.json();

          if (data?.payment_status === "PAID") {
            clearInterval(intervalId);
            window.location.href = `${basePath}/success?reserve_id=${reserve_id}&method=pix`;
          } else {
            // 10s timeout to show the alert
            if (counter === 5) {
              setTimeoutProcess(true);
            }

            if (data?.payment_status === "REJECTED") {
              clearInterval(intervalId);
              setRejectedPayment(true);
            }
          }

          counter++;
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }, 2000);

      const timeoutId = setTimeout(() => {
        clearInterval(intervalId);
      }, 10 * 60 * 1000); // 10 minutes

      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    }
  }, [process]);

  const whatsappSupport = () => {
    const link = `https://wa.me/+${whatsapp_number}/?text=${t(
      "index.whatsappSupportMessage"
    )}${reserve_id}`;
    window.open(link, "_blank");
  };

  const infoAlertStyle = {
    borderRadius: "10px",
    backgroundColor: "#E5E3FC",
    border: "solid 1px #3C2CE8",
    color: "#0E0849",
    fontWeight: "normal",
    boxShadow: "none",
  };

  return (
    <div>
      {process && !rejectedPayment && (
        <div className="text-[#006FE8] w-full flex items-center justify-center flex-col max-w-[300px] md:max-w-[397px] text-left mx-auto mt-24">
          <CircularProgress size={64} />
          <h2 className="text-2xl font-bold leading-8 mt-12 mb-4">
            Estamos aguardando o pagamento.
          </h2>
          <p className="text-xl">
            Após o pagamento na área Pix do seu banco,
            <span className="font-bold">volte a esta página</span> para
            finalizar a compra e receber sua apólice.
          </p>
        </div>
      )}

      {!process && !rejectedPayment && (
        <div className="mt-5">
          <Alert severity="info" variant="filled" sx={infoAlertStyle}>
            <Trans
              i18nKey={"steps.payment.pixInfoText"}
              components={{ strong: <strong></strong> }}
            />
          </Alert>
        </div>
      )}

      {timeoutProcess && (
        <div className="mt-12 w-full max-w-[397px] mx-auto">
          <Alert severity="info" variant="filled" sx={infoAlertStyle}>
            <Trans
              i18nKey={"steps.payment.pixTimeoutProcess"}
              components={{ strong: <strong></strong> }}
            />
          </Alert>
        </div>
      )}

      {rejectedPayment && (
        <>
          <div className="mt-9 flex flex-col items-center justify-center space-y-6">
            <h1 className="text-[#006FE8] font-bold text-2xl">
              Seu pagamento foi rejeitado.
            </h1>

            <svg
              width="70"
              height="68"
              viewBox="0 0 70 68"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.222656 0H69.7773V67.8969H0.222656V0Z"
                fill="white"
                fill-opacity="0.01"
              />
              <path
                d="M35.0006 62.239C43.0035 62.239 50.2488 59.0725 55.4933 53.9529C60.7379 48.8333 63.9818 41.7607 63.9818 33.9486C63.9818 26.1365 60.7379 19.0639 55.4933 13.9443C50.2488 8.82472 43.0035 5.6582 35.0006 5.6582C26.9978 5.6582 19.7525 8.82472 14.5079 13.9443C9.26336 19.0639 6.01953 26.1365 6.01953 33.9486C6.01953 41.7607 9.26336 48.8333 14.5079 53.9529C19.7525 59.0725 26.9978 62.239 35.0006 62.239Z"
                fill="white"
                fill-opacity="0.74"
                stroke="#4C64D6"
                stroke-width="3.91479"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M35.0001 52.3372C37.0008 52.3372 38.6227 50.754 38.6227 48.8009C38.6227 46.8479 37.0008 45.2646 35.0001 45.2646C32.9994 45.2646 31.3774 46.8479 31.3774 48.8009C31.3774 50.754 32.9994 52.3372 35.0001 52.3372Z"
                fill="#4C64D6"
              />
              <path
                d="M34.9998 16.9741V39.6064"
                stroke="#4C64D6"
                stroke-width="3.91479"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p className="text-[#2454AD] font-semibold text-base text-center">
              {t("steps.payment.rejectedPayment")}
            </p>

            <button
              onClick={backFromPix}
              className="text-[#006FE8] font-semibold text-base text-center underline"
            >
              {t("steps.payment.retryPayment")}
            </button>
          </div>

          <div className="pt-10 max-w-[335px] mx-auto">
            <button
              className="btn-continue-workflow2"
              type="button"
              onClick={whatsappSupport}
            >
              {t("steps.payment.chatSupport")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default PixProcess;
