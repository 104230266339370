import React, { useCallback, useEffect, useState } from "react";
import { Card, CardContent, Tooltip, Button, Alert, Link } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  () => ({
    transferDataFormButton: {
      backgroundColor: "#7BD0C2 !important",
      "&:hover": {
        backgroundColor: "#FA9134 !important",
      },
    },
  }),
  { classNamePrefix: "transferDataForm" }
);

const TransferCard = ({ onSubmit }) => {
  const [copied, setCopied] = useState(false);
  const [viewTransferData, setViewTransferData] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (viewTransferData) {
      onSubmit();
    }
  }, [viewTransferData]);

  const copyToClipboard = () => {
    const textToCopy = "0720042720000001679504";
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        onSubmit();
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Error al copiar al portapapeles: ", error);
      });
  };

  const renderAlertMsg = useCallback(() => {
    return (
      <Alert
        severity="warning"
        variant="filled"
        sx={{
          borderRadius: "10px",
          backgroundColor: "#FFF7B0",
          borderColor: "#EDC95E",
          color: "#453608",
          fontWeight: "normal",
          border: "solid 1px #EDC95E",
          boxShadow: "none",
        }}
      >
        Recuerda enviarnos el <b>comprobante de transferencia</b> a:{" "}
        <Link href="mailto:hola@assist-365.com">hola@assist-365.com</Link> para
        que podamos validarlo, indicando el{" "}
        <b>número de DNI o pasaporte del pasajero.</b> Una vez enviado el
        comprobante, recibirás tu Voucher en un plazo máximo de 72hs hábiles.
        Gracias!
      </Alert>
    );
  }, []);
  return (
    <>
      <div className="text-center mb-8">
        {!viewTransferData && (<Button
          className={classes.transferDataFormButton}
          variant="contained"
          type="button"
          onClick={() => setViewTransferData(true)}
        >
          <span className="text-[20px] mx-6">
            {t("form.button.viewTransferData")}
          </span>
        </Button>)}
      </div>

      {viewTransferData && (<div className="flex flex-col">
        <Card>
          <CardContent>
            <div className="text-center mb-4">
              <h1 className="font-semibold text-[#006FE8] mb-2 text-[20px]">
                Banco Santander
              </h1>
              <p className="mb-4">
                Razón social: International Assistance SRL{" "}
                <b className="ml-2">Cuit: 30-71481365-6</b>
              </p>
              <p className="text-[#506373] text-sm mb-4 text-center">
                Número de cuenta: CC en Pesos 042-016795/0
              </p>
              <p className="text-[#506373]">
                <b>CBU: 0720042720000001679504</b>{" "}
                <Tooltip title={copied ? "¡Copiado!" : "Copiar"} placement="top">
                  <FileCopyIcon
                    onClick={copyToClipboard}
                    style={{ fill: "#675CE5", cursor: "pointer", fontSize: "20px" }}
                  />
                </Tooltip>
              </p>
            </div>
            <p className="text-[#506373] text-center">Alias: internationalassist</p>
          </CardContent>
        </Card>
        <div className="mt-7">{renderAlertMsg()}</div>
      </div>)}
    </>

  );
};

export default TransferCard;
