import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useFormData } from "../../context/formContext";
import QRCode from "qrcode.react";

import {
  emitPOSTController,
  payPOSTController,
} from "../../dataController/dataController";
import { extractQRData, removeBeneficiaryKeys } from "../../helpers/sanitize";
import CircularProgress from "@mui/material/CircularProgress";
import { useActionButton } from "../../context/actionButtonContext";
import { useLoader } from "../../context/loaderContext";

const infoAlertStyle = {
  borderRadius: "10px",
  backgroundColor: "#E5E3FC",
  border: "solid 1px #3C2CE8",
  color: "#0E0849",
  fontWeight: "normal",
  boxShadow: "none",
};
const PixCard = ({ pixProcess }) => {
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();
  const { setFormData, parsedData } = useFormData();
  const [reserveData, setReserveData] = useState(parsedData);
  const [pixData, setPixData] = useState({
    data: {
      status: false,
      status_msg: "",
      qrCode: "",
      qrCodeString: null,
    },
  });

  const [codeToCopy, setCodeToCopy] = useState(null);
  const [pixMethod, setPixMethod] = useState(null);
  const { setLoader } = useLoader();

  const handlePixProcess = (method) => {
    console.log("handlePixProcess", method);
    setPixMethod(method);
    if (method === "copy") {
      copyToClipboard();
    }
    pixProcess(method, reserveData?.reserve_id);
  };

  useEffect(() => {
    console.log("reserveData ->", reserveData);
  }, [reserveData]);

  const { setActionButtonEnabled, setActionButtonAvailable } =
    useActionButton();
  setActionButtonAvailable(false);

  const [showCpfError, setShowCpfError] = useState(false);

  const copyToClipboard = () => {
    console.log("copiar al portapapeles ", codeToCopy);

    navigator.clipboard
      .writeText(codeToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Error al copiar al portapapeles: ", error);
      });
  };
  const handleSubmissionError = (error) => {
    console.error("Error during submission:", error);
  };

  useEffect(() => {
    setActionButtonAvailable(false);

    if (parsedData) {
      parsedData.method = "Dlocal PIX";
      parsedData.payment_method = "Dlocal PIX";

      const fetchData = async () => {
        try {
          setLoader(true);
          const result = await emitPOSTController.fetchData({
            body: parsedData,
          });

          const reserve = result?.data?.reserve;
          const status = result?.data?.status;

          if (status) {
            setReserveData((prev) => ({
              ...prev,
              ...{
                reserve_id: reserve.new_id,
                reserve_status: status,
              },
            }));
          }
        } catch (error) {
          handleSubmissionError(error);
        } finally {
          setLoader(false);
          console.info("Submission done");
        }
      };
      fetchData();
    }
  }, [parsedData]);

  useEffect(() => {
    if (reserveData.reserve_status) {
      const fetchData = async () => {
        try {
          setLoader(true);
          const payload = removeBeneficiaryKeys(reserveData);
          const payer_name =
            parsedData["beneficiary[name][0]"] +
            " " +
            parsedData["beneficiary[lastname][0]"];
          const payer_email = parsedData["beneficiary[email][0]"];
          const payer_document = parsedData["beneficiary[doc_number][0]"];

          // const payer_document = 48552748878;
          // const payer_name = "Teste Teste";
          // const payer_email = "test@test.com";

          console.log("payload pay");
          console.log(payload);

          const payData = {
            ...payload,
            ...{
              method: "Dlocal PIX",
              payment_method: "Dlocal PIX",
              pix: "true",
              payer_email: payer_email,
              payer_name: payer_name,
              payer_document: payer_document,
              country_code: "BR",
              currency_code: "BRL",
            },
          };
          const result = await payPOSTController.fetchData({
            body: payData,
          });

          if (result?.data?.status) {
            const pixData = {
              data: {
                status_msg: result?.data?.status_msg,
                status: result?.data?.status,
                qrCode: result?.data?.barcode,
                qrCodeString: result?.data?.number,
              },
            };

            setPixData(pixData);
            setCodeToCopy(result?.data?.number);
          } else {
            setShowCpfError(true);
          }
        } catch (error) {
          handleSubmissionError(error);
        } finally {
          console.info("Submission done");
          setLoader(false);
        }
      };
      fetchData();
    }
  }, [reserveData]);

  return (
    <>
      {showCpfError && (
        <div className="w-full">
          <Alert severity="error" variant="filled">
            {t("steps.payment.pixCpfError")}
          </Alert>
        </div>
      )}
      {!showCpfError && (
        <>
          <div className="flex flex-col">
            {(pixMethod === "qr" || !pixMethod) && (
              <>
                <div className="hidden md:block">
                  <h2 className="font-bold text-4xl text-[#006FE8] mb-8">
                    Pix
                  </h2>
                  <h1 className="font-bold text-2xl text-[#006FE8] mb-8">
                    {t("steps.payment.title.pixQr")}
                  </h1>
                  <p className="text-base text-[#122A56] font-titillium my-5">
                    {t("steps.payment.infoQr")}
                  </p>
                  {!pixMethod && (
                    <div className="text-center">
                      <Button
                        onClick={() => handlePixProcess("qr")}
                        sx={{
                          margin: "auto",
                          backgroundColor: "#FA9134",
                          "&:hover": {
                            backgroundColor: "#675CE5",
                          },
                        }}
                        variant="contained"
                      >
                        <span className="text-[20px] mx-6">
                          {t("steps.payment.showQrButtonLabel")}
                        </span>
                      </Button>
                    </div>
                  )}
                  {pixMethod === "qr" && (
                    <div className="mb-6 flex justify-center items-center">
                      <div className="w-[200px] h-[200px] bg-white flex justify-center items-center">
                        {pixData?.data?.status ? (
                          <QRCode
                            value={pixData?.data?.qrCodeString}
                            size={200}
                            includeMargin
                          />
                        ) : (
                          <CircularProgress
                            size={48}
                            sx={{ color: "#006FE8" }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {!pixMethod && (
                  <div>
                    <h1 className="font-bold text-2xl text-[#006FE8] md:mt-8 mb-8">
                      {t("steps.payment.title.Pix")}
                    </h1>
                    <p className="text-[#122A56] text-base font-titillium mb-6">
                      {t("steps.payment.pixCopyCode")}
                    </p>
                    <div className="mb-6">
                      <TextField
                        fullWidth
                        value={codeToCopy}
                        InputProps={{
                          endAdornment: !pixData?.data?.status && (
                            <InputAdornment position="end">
                              <CircularProgress size={20} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    {!pixMethod && (
                      <div className="text-center mb-10">
                        <Tooltip
                          title={copied ? "¡Copiado!" : "Copiar"}
                          placement="top"
                        >
                          <Button
                            onClick={() => handlePixProcess("copy")}
                            sx={{
                              backgroundColor: "#675CE5",
                              "&:hover": {
                                backgroundColor: "#FA9134",
                              },
                            }}
                            variant="contained"
                          >
                            <span className="text-[20px] mx-6">
                              {t("steps.payment.copyButtonLabel")}
                            </span>
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PixCard;
