import "./assets/css/core.css";
import { I18nextProvider } from "react-i18next";
import i18n from "../src/i18n/i18n";
import { LanguageProvider } from "../src/context/LanguageContext";
import { lazy, Suspense, useEffect, useState } from "react";

const Footer = lazy(() => import("./components/footer/footer"));

export default function Root(props) {
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    let app = window.location.pathname.split("/")[2];
    const excluded_apps = [
      "products",
      "cancellation-service",
      "cancel-subscription",
    ];
    const state = excluded_apps.includes(app) || !app;
    setVisibility(!state);
  }, []);

  return (
    <LanguageProvider>
      <I18nextProvider i18n={i18n}>
        {visibility && (
          <Suspense fallback={<div></div>}>
            <Footer />
          </Suspense>
        )}
      </I18nextProvider>
    </LanguageProvider>
  );
}
