import React, { useCallback, useEffect, useMemo, useState } from "react";
import BackButton from "../../components/BackButton";
import { useTranslation } from "react-i18next";
import { Alert, Button, Link } from "@mui/material";
import { useLocation } from "../../context/locationContext";
import SuccessGuy from "../../assets/illustrations/svg/SuccessGuy";
import { makeStyles } from "@mui/styles";
import * as paymentProcessHelper from "../../helpers/paymentProcessHelper";
import { voucherFetchController } from "../../dataController/dataController";
import { useLoader } from "../../context/loaderContext";

const alertStyle = {
  borderRadius: "10px",
  backgroundColor: "#FFF7B0",
  border: "solid 1px #EDC95E",
  color: "#453608",
  fontWeight: "normal",
  boxShadow: "none",
};

const useStyles = makeStyles(
  () => ({
    congratsButton: {
      marginLeft: "20px !important",
      backgroundColor: "#7BD0C2 !important",
      "&:hover": {
        backgroundColor: "#FA9134 !important",
      },
    },
  }),
  { classNamePrefix: "congrats" }
);
const Congrats = () => {
  const { t, i18n } = useTranslation();
  let dataLayer = window.dataLayer || [];

  const {
    basePath,
    id: transactionId,
    getParamFromUrl,
    url: baseUrl,
  } = useLocation();
  const classes = useStyles();
  const [showVoucher, setShowVoucher] = useState(false);
  const [voucher, setVoucher] = useState([]);
  const form = getParamFromUrl("form");
  const method = getParamFromUrl("method");
  const reserveId = getParamFromUrl("reserve_id");
  const isTransferMethod = method === "deposit";
  const url = new URL(baseUrl.href);
  const externalRefParam = url.searchParams.has("external_reference");
  const { setLoader } = useLoader();

  const sendEmail = async () => {
    const data = {
      reserve_id: getParamFromUrl("id"),
      email: getParamFromUrl("email"),
      payment_method: "deposit",
    };

    setLoader(true);
    const result = await paymentProcessHelper.sendDepositNotification(data);
    setLoader(false);
  };

  const title = useMemo(() => {
    if (!isTransferMethod) {
      return t("congrats.transfer.normal.title");
    } else {
      return form
        ? t("congrats.transfer.form.title")
        : t("congrats.transfer.normal.title");
    }
  }, [form, isTransferMethod]);

  // console.log(title);
  // console.log(i18n.language);

  const helpText = form
    ? t("congrats.transfer.form.notReceiveIt")
    : t("congrats.transfer.normal.needHelp");

  const helpLink = form
    ? t("congrats.transfer.form.helpLink")
    : t("congrats.transfer.normal.helpLink");

  const emitVoucher = useCallback(async () => {
    const result = await paymentProcessHelper.emitVoucher(
      {},
      { reserve_id: reserveId }
    );

    if (result?.data?.status) {
      getVoucher();
    } else {
      alert("Error!!");
    }
  }, [reserveId]);

  const getVoucher = useCallback(async () => {
    if (reserveId) {
      fetch(`https://app.assist-365.com/api/r?id=${reserveId}`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((dataRes) => {
          if (dataRes.status === true) {
            dataLayer.push({
              event: "purchaseDone",
              ecommerce: {
                purchase: {
                  actionField: {
                    id: reserveId,
                    revenue: Number(dataRes.total_price),
                    tax: 0.0,
                    shipping: 0.0,
                    currency: "USD",
                  },
                  products: [
                    {
                      name: dataRes.product_name,
                      id: dataRes.product_id.toString(),
                      price: Number(dataRes.total_price),
                    },
                  ],
                },
              },
            });

            // try {

            //   const { currency_symbol, transaction_id, total_price, product_id, product_name } = dataRes;

            //   const impression = {
            //     "event": "purchase",
            //     "currency": currency_symbol,
            //     "transaction_id": transaction_id,
            //     "value": total_price,
            //     "items": [{
            //       "item_id": product_id,
            //       "item_name": product_name.trim()
            //     }]
            //   };

            //   dataLayer.push(impression);

            // } catch (error) {
            //   console.error(error);
            // }
          }
        })
        .catch((error) => console.log(error));
    }

    const intervalId = setInterval(async () => {
      try {
        setLoader(true);
        const result = await voucherFetchController.fetchData(reserveId);

        if (result.data?.vouchers?.length > 0) {
          clearInterval(intervalId);
          setVoucher(result.data?.vouchers);
          setLoader(false);
        }
      } catch (error) {
        console.warn(error);
      } finally {
        console.warn("done");
      }
    }, 4000);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 30 * 60 * 1000); // 10 minutes

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [reserveId]);

  useEffect(() => {
    if (reserveId) {
      if (externalRefParam) {
        emitVoucher();
      } else {
        getVoucher();
      }
    }
  }, [reserveId, externalRefParam]);

  const handleBack = () => {
    window.location.href = "https://assist-365.com";
  };

  return (
    <main className="max-w-[1280px] mx-auto bg-[#EFF1F4] px-5 py-8">
      <div className="mb-5 w-full">
        <div className="mb-4 sm:mb-0">
          <BackButton label={t("index.home")} event={handleBack} />
        </div>
      </div>
      <div className="max-w-[635px] mx-auto mb-12 text-center">
        <h1 className="font-bold text-[30px] text-[#006FE8] mb-2">
          {isTransferMethod
            ? t("congrats.transfer.form.title")
            : t("congrats.transfer.normal.title")}
        </h1>
        {form && isTransferMethod && (
          <p className="text-[#323334]">
            {t("congrats.transfer.form.checkSpam")}
          </p>
        )}

        <div className="flex justify-center mb-6">
          <SuccessGuy />
        </div>

        {!isTransferMethod && (
          <p className="text-[#323334] mb-2">
            {t("congrats.transfer.normal.coverageForYourNextTrip")}
          </p>
        )}

        {isTransferMethod && !form && (
          <p className="text-[#323334] mb-2">
            {t("congrats.transfer.normal.rememberSendVoucher")}
          </p>
        )}

        {isTransferMethod && (
          <p className="text-[#323334] mb-6">
            {t("congrats.transfer.form.otherOptionToPay")}
          </p>
        )}

        {isTransferMethod && (
          <div className="mb-8">
            <Alert severity="warning" variant="filled" sx={alertStyle}>
              Recuerda enviarnos el <b>comprobante de transferencia</b> a:{" "}
              <Link href="mailto:hola@assist-365.com">hola@assist-365.com</Link>{" "}
              para que podamos validarlo, indicando el{" "}
              <b>número de DNI o pasaporte del pasajero.</b> Una vez enviado el
              comprobante, recibirás tu Voucher en un plazo máximo de 72hs
              hábiles. Gracias!
            </Alert>
          </div>
        )}

        {isTransferMethod && (
          <>
            <p className="text-[#006FE8] font-bold text-[20px] mb-6">
              {helpText}
            </p>
            <button
              className="text-[#006FE8] font-bold underline"
              onClick={sendEmail}
              type="button"
            >
              {helpLink}
            </button>
          </>
        )}
        {!isTransferMethod && !showVoucher && voucher?.length > 0 && (
          <div className="mt-6">
            <Button
              className={classes.congratsButton}
              variant="contained"
              onClick={() => setShowVoucher(true)}
            >
              <span className="text-[20px] mx-6">
                {t("congrats.transfer.normal.voucherDownload")}
              </span>
            </Button>
          </div>
        )}
        {showVoucher && (
          <div className="mt-6">
            <h3 className="font-bold text-[24px] text-[#006FE8] mb-2">
              {t("congrats.transfer.normal.downloadYourVoucher")}
            </h3>
            <ul>
              {voucher.map((row, index) => (
                <li className="mb-4" key={index}>
                  <Link href={row?.download_link}>
                    {t("congrats.transfer.normal.clientDownloadVoucher", {
                      client: row?.fullname,
                    })}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </main>
  );
};

export default Congrats;
