import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePaymentMethod } from "../../context/paymentMethodContext";
import { Alert } from "@mui/material";
import TransferDataForm from "../../components/TransferDataForm";
import PixCard from "../../components/PixCard";
import PayPalButton from "../../components/Paypal";
import MercadoPago from "../../components/MercadoPago";
import { useActionButton } from "../../context/actionButtonContext";
import CieloCreditCardForm from "../../components/CieloCreditCardForm";
import { Controller, useForm } from "react-hook-form";
import PrismaCreditCardForm from "../../components/PrismaCreditCardForm";
import DlocalCreditCardForm from "../../components/DlocalCreditCardForm";
import { useQuote } from "../../context/quoteContext";
import Rebill from "../../components/Rebill";
import DlocalMercadoPago from "../../components/DlocalMercadoPago";
import MercadoPagoHandler from "../../handlers/MercadoPagoHandler";
import { useFormData } from "../../context/formContext";
import { useLoader } from "../../context/loaderContext";

const alertStyle = {
  borderRadius: "10px",
  backgroundColor: "#FFF7B0",
  border: "solid 1px #EDC95E",
  color: "#453608",
  fontWeight: "normal",
  boxShadow: "none",
};

const Payment = ({ isValidForm, paymentFormRef, totalPax, purchaseDetail, pixProcess }) => {
  const { t } = useTranslation();
  const { paymentMethod } = usePaymentMethod();
  const { setActionButtonAvailable } = useActionButton();
  const { setActionButtonEnabled } = useActionButton();
  const formRef = useRef(null);
  const { data, isLink } = useQuote();
  const { setFormData, parsedData } = useFormData();
  const [requestError, setRequestError] = useState(null);
  const { setLoader } = useLoader();

  let dataLayer = window.dataLayer || [];

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm();

  const getTitleByPaymentMethod = useMemo(
    () => t(`steps.payment.title.${paymentMethod}`),
    [t, paymentMethod]
  );

  const renderAlertMsg = useMemo(
    () =>
      (paymentMethod === "transfer" || paymentMethod === null) && (
        <Alert severity="warning" variant="filled" sx={alertStyle}>
          {t("steps.payment.alert")}
        </Alert>
      ),
    [paymentMethod, t]
  );

  const controlError = (error) => {
    setRequestError(error);
  };


  // const setDataLayer = () => {
  //   try {

  //     const { currency_symbol, total_price, name, product_id } = data.quote;
  //     const impression = {
  //       "event": "add_payment_info",
  //       "currency": currency_symbol,
  //       "value": total_price,
  //       "payment_type": paymentMethod,
  //     };
  //     const voucher = localStorage.getItem("voucher") ?? null;
  //     if (voucher) {
  //       impression["coupon"] = voucher;
  //     }

  //     dataLayer.push({ ecommerce: null });

  //     impression["ecommerce"] = {
  //       items: [
  //         {
  //           "item_id": product_id,
  //           "item_name": name,
  //         }
  //       ]
  //     }

  //     dataLayer.push(impression);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const renderContent = useMemo(() => {
    // if (!isLink) {
    //   setDataLayer();
    // }

    switch (paymentMethod) {
      case "deposit":
        return (
          <>
            <h1 className="font-bold text-2xl text-[#006FE8] mb-8">
              {getTitleByPaymentMethod}
            </h1>
            <div className="mb-6">{renderAlertMsg}</div>
            <div>
              <TransferDataForm formRef={formRef} />
            </div>
          </>
        );
      case "cielo":
        return (
          <div className="mb-8">
            <CieloCreditCardForm
              control={control}
              Controller={Controller}
              errors={errors}
              setValue={setValue}
              purchaseDetail={purchaseDetail}
              handleSubmit={handleSubmit}
              formRef={formRef}
            />
          </div>
        );
      case "decidirCredit":
      case "decidirDebit":
      case "decidir":
        return (
          <div className="mb-8">
            <PrismaCreditCardForm
              control={control}
              Controller={Controller}
              errors={errors}
              setValue={setValue}
              purchaseDetail={purchaseDetail}
              handleSubmit={handleSubmit}
              formRef={formRef}
              trigger={trigger}
            />
          </div>
        );

      case "dlocal":
        //setActionButtonEnabled(false);
        return (
          <div className="mb-8">
            <DlocalCreditCardForm
              purchaseDetail={purchaseDetail}
              formRef={formRef}
            />
          </div>
        );
      case "Pix":
        return (
          <div className="mb-8">
            <PixCard pixProcess={pixProcess} />
          </div>
        );
      case "dlocalmp":
        return (
          <div className="mb-8">
            <DlocalMercadoPago />
          </div>
        );
      case "mp":
      case "mercadopago":
        return (
          <>
          {isLink && ( 
            <MercadoPagoHandler
              parsedData={parsedData}
              purchaseDetail={purchaseDetail}
              controlError={controlError}
            />)}

          </>
        )       
      case "rebillCash":
      case "rebillTransfer":
        return (
          <Rebill
            purchaseDetail={purchaseDetail}
            transaction_type={paymentMethod}
          />
        );
      default:
        setActionButtonAvailable(false);
        return (
          <div className="mb-8">
            <PayPalButton purcheaseDetails={purchaseDetail} />
          </div>
        );
    }
  }, [
    control,
    Controller,
    formRef,
    paymentMethod,
    getTitleByPaymentMethod,
    renderAlertMsg,
    setValue,
    errors,
    purchaseDetail,
    trigger,
  ]);

  useEffect(() => {
    isValidForm(isValid);
  }, [isValid]);

  useEffect(() => {
    paymentFormRef(formRef);
  }, [formRef]);

  return <section>{renderContent}</section>;
};

export default Payment;